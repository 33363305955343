<template>
  <div class="box">
    <div class="pic" style="padding-bottom:0px;position: relative;">
      <img src="@/assets/top.png" alt="">
      <div class="content">
        <div class="btn" @click="$router.push({ path: '/register',query:{diff:0} })">免费版</div>
        <a :href="loginUrl">专业版</a>
        <div class="info" id="info">
          <div class="item">
            <p class="number">{{infoData.registerTotal}}</p>
            <p>申请注册</p>
          </div>
          <div class="item">
            <p class="number">{{infoData.siteTotal}}</p>
            <p>网站上线</p>
          </div>
          <div class="item">
            <p class="number">{{infoData.inquiryTotal}}</p>
            <p>海外询盘</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pic">
      <img src="@/assets/entrance.png" alt="">
    </div>
    <div class="footer" v-if="isShow">
      <div class="btn clearfix">
        <div class="fl-btn" @click="$router.push({ path: '/register',query:{diff:0} })">免费版</div>
        <div class="fr-btn" @click="$router.push({ path: '/register',query:{diff:1} })">专业版</div>
      </div>
      <!-- <p><a :href="loginUrl" class="link-text">已有账号</a></p> -->
    </div>
  </div>
</template>
<script>
import global from "../../../global.js";
import ajax from "../../js/ajax.js";
export default {
  components: {},
    data() {
        return {
          isShow:false,
          infoData:{
            registerTotal:"",
            siteTotal:"",
            inquiryTotal:"",
          }
        }
    },
    computed: {
        loginUrl() {
             switch (global.env) {
                case "dev": // dev
                    return "http://10.10.20.228:3030/register?diff=1";
                case "test": // test
                    return "http://testregister.linkjoint.cn/register?diff=1";
                case "pro": // pro
                    return "https://accounts.mysitefy.com/register?diff=1";
                default:
                    return "http://192.168.2.101:8080/register?diff=1"; // 本机环境默认开发
            }
        }
    },
    created(){
      this.getData();
    },
    mounted(){
      window.addEventListener("scroll", this.handleScroll, true);
    },
    methods: {
      async getData(){
            const { data } = await ajax.get("/api/mini/user/getTotal");
            if (data.code === 200) {
                this.infoData = data.data;
            }
      },
      handleScroll () {
        const heightTop = document.querySelector("#info")?.getBoundingClientRect().top; // 56为头部高度
        if(heightTop < 100){
          this.isShow = true
        }else {
          this.isShow = false
        }
      }
    }

}
</script>
<style lang="scss" scoped>
.box {
  width: 100%;
}
.clearfix:after{
    display:block;
    clear:both;
    content: "";
    visibility:hidden;
    height:0;
}
.clearfix{zoom:1}
  .pic {
    width: 100%;
    padding-bottom:272px;
    img{
        width:100%;
    }
    .content {
      text-align: center;
      position: absolute;
      bottom: -70px;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
      a {
        display: inline-block;
        margin-top: 48px;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        text-decoration: underline;
      }
      .btn {
        background: #526FDE;
        border: 2px solid #EAFCF5;
        border-radius: 37px;
        width: 280px;
        height: 74px;
        margin: 0 auto;
        line-height: 74px;
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
      .info {
          display: flex;
          margin: 0 42px;
          margin-top: 70px;
          padding: 44px;
          background: #FFFFFF;
          box-shadow: 0px 6px 20px 0px rgba(79, 79, 235, 0.2);
          border-radius: 10px;
          .item{
            flex:1;
            p {
              font-size: 26px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #555555;
            }
            .number {
              font-size: 50px;
              font-family: Arial;
              font-weight: 400;
              color: #3263E1;
              margin-bottom: 30px;
            }
          }
      }
    }
  }
  .footer {
    height: 272px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: #fff;
      p {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #0B47E8;
      text-align: center;
      .link-text {
          text-decoration: none;
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #0B47E8;
      }
    }
    .btn {
      padding: 50px 90px 50px 90px;
      .fl-btn {
        // float: left;
        margin-bottom: 26px;
        height: 72px;
        line-height:72px;
        text-align: center;
        width: calc(100%);
        font-size: 32px;
        font-weight: 500;
        color: #FFFFFF;
        background: linear-gradient(116deg, #174BE9 0%, #006CFF 78%);
        border-radius: 36px;
        &:hover{
          cursor: pointer;
        }
      }
      .fr-btn {
        // float: right;
        height: 68px;
        line-height:72px;
        text-align: center;
        width: calc(100%);
        border: 2px solid #174BEA;
        border-radius: 36px;
        font-size: 32px;
        font-weight: 500;
        color: #174BEA;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
 @media (max-width: 767px) {
    .pic {
      width: 100%;
      padding-bottom:136px;
       .content {
          position: absolute;
          bottom: -35px;
          width: 100%;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
          a {
            display: inline-block;
            margin-top: 24px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            text-decoration: underline;
          }
          .btn {
            background: #526FDE;
            border: 2px solid #EAFCF5;
            border-radius: 37px;
            width: 140px;
            height: 37px;
            margin: 0 auto;
            line-height: 37px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
          }
           .info {
              display: flex;
              margin: 0 21px;
              margin-top: 35px;
              padding: 22px;
              background: #FFFFFF;
              box-shadow: 0px 6px 20px 0px rgba(79, 79, 235, 0.2);
              border-radius: 10px;
              .item{
                flex:1;
                    p {
                      font-size: 13px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #555555;
                    }
                    .number {
                      font-size: 25px;
                      font-family: Arial;
                      font-weight: 400;
                      color: #3263E1;
                      margin-bottom: 15px;
                    }
              }
          }
        }
    }
  .footer {
    height: 136px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: #fff;
      p {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #0B47E8;
      text-align: center;
      .link-text {
          text-decoration: none;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #0B47E8;
      }
    }
    .btn {
      padding: 25px 45px 25px 45px;
      .fl-btn {
        // float: left;
        margin-bottom: 13px;
        height: 37px;
        line-height:36px;
        text-align: center;
        width: calc(100%);
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
        background: linear-gradient(116deg, #174BE9 0%, #006CFF 78%);
        border-radius: 18px;
      }
      .fr-btn {
        // float: right;
        height: 34px;
        line-height:36px;
        text-align: center;
        width: calc(100%);
        border: 1px solid #174BEA;
        border-radius: 18px;
        font-size: 16px;
        font-weight: 500;
        color: #174BEA;
      }
    }
  }
 }
</style>